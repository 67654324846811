import React from 'react';
var adsbygoogle;
export default class AdComponent extends React.Component {
    componentDidMount() {
        (adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <div style={{width: "100%", height: "160px"}}>
                <ins className="adsbygoogle"
                    style={{ display: "block", height: "150px"}}
                    data-ad-client="ca-pub-6705173231457349"
                    data-ad-slot="3694264782"
                    data-full-width-responsive="true"></ins>
            </div>
        );
    }
}