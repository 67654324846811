import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
// Redux store provider
import { Provider } from "react-redux";
import { store } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
// Layout Routes
import layoutRoutes from "./routes/index.jsx";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";

import Leaflet from "views/Leaflet";

//Fake Server
import { serverResponse } from './util/fakeServer';

import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";

import cssVars from 'css-vars-ponyfill';
import { isIE } from 'react-device-detect';

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || "italian").locale];



class App extends Component {

    render() {

        let theme = serverResponse.config;
        let root = document.documentElement;
        let vh = window.innerHeight * 0.01;

        if (isIE) {
            cssVars({
                rootElement: root,
                variables: {
                    '--primary-color': theme['primary_color'],
                    '--secondary-color': theme['secondary_color'],
                    '--tertiary-color': theme['tertiary_color'],
                    '--hover-color': theme['hover_color'],
                    '--primary-alpha-color': theme['primary-alpha-color']
                }
            });
        } else {
            root.style.setProperty('--primary-color', theme["primary_color"]);
            root.style.setProperty('--secondary-color', theme["secondary_color"]);
            root.style.setProperty('--tertiary-color', theme["tertiary_color"]);
            root.style.setProperty('--hover-color', theme["hover_color"]);
            root.style.setProperty('--primary-alpha-color', theme["primary-alpha-color"]);
            root.style.setProperty('--vh', `${vh}px`);
        }

        // var titleMeta = document.createElement('meta');
        // titleMeta.setAttribute('property', 'og:title');
        // titleMeta.content = serverResponse.config.ogTitleMeta;
        // document.getElementsByTagName('head')[0].appendChild(titleMeta);

        // var urlMeta = document.createElement('meta');
        // urlMeta.setAttribute('property', 'og:url');
        // urlMeta.content = serverResponse.config.shareFlyerURL;
        // document.getElementsByTagName('head')[0].appendChild(urlMeta);

        // var descMeta = document.createElement('meta');
        // descMeta.setAttribute('property', 'og:description');
        // descMeta.content = serverResponse.config.ogDescriptionMeta;
        // document.getElementsByTagName('head')[0].appendChild(descMeta);

        // var imgMeta = document.createElement('meta');
        // imgMeta.setAttribute('property', 'og:image');
        // imgMeta.content = serverResponse.config.ogImageMeta;
        // document.getElementsByTagName('head')[0].appendChild(imgMeta);

        var default_redirect_path = "/categories";
        if (serverResponse.leaflet.id_campaign == -1) {
            default_redirect_path = '/no-leaflet';
        } else if (serverResponse.config.has_pages) {
            default_redirect_path = '/leaflet';
        }

        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >

                <ThemeProvider theme={themes[themeConfig.theme]}>
                    <StyleRoot>
                        <Provider store={store}>
                            {/*Start layout routes */}
                            <Router basename={process.env.PUBLIC_URL}>
                                <Switch>
                                    <Route exact path='/' render={() => (
                                        <Redirect to={default_redirect_path} />
                                    )} />
                                    {layoutRoutes.map((prop, key) => {
                                        return (
                                            <Route
                                                path={prop.path}
                                                //component={prop.component}
                                                render={(routeProps) => (
                                                    <prop.component {...routeProps} themeMapping={theme} />
                                                )}
                                                key={key}
                                            />
                                        );
                                    })}
                                </Switch>
                            </Router>
                            {/*End layout routes */}
                        </Provider>
                    </StyleRoot>
                </ThemeProvider>
            </IntlProvider>
        );
    }
}

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
