import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../settings/gaConfig';
import { isMobile } from "react-device-detect";

const img = require("assets/images/no_vol.png");
const img_mobile = require("assets/images/no_vol_m.png");

class Novol extends Component {

  componentDidMount() {
  }

  componentWillMount() {
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            {!isMobile && <img src={img} className="img-fluid" />}
            {isMobile && <img src={img_mobile} className="img-fluid" />}
          </div>
        </div>
      </div>
    );

  }
}

export default Novol;