import React, { Fragment } from "react";
import { sidebarData } from "util/data/sidebar";
import ShareFlyer from '../share/ShareFlyer';
import IntlMessages from "util/intlMessages";
import NavList from "components/sidebar/NavList";
import { reloadTutorial } from 'views/Categories';

const BottomPrerollBar = props => {
    let listNameStyle;
    let sidebar;
    let appNameStyle = {};

    const {
        mini,
        drawerWidth,
        miniDrawerWidth,
        onMouseEnter,
        onMouseLeave,
        sidebarTheme,
        closeDrawer,
        themeSetting
    } = props;

    const reopenTutorial = () => {
        reloadTutorial();
    }

    return (
        <div id="bottomSideBar">
            <ul className="nav">
                <li>
                    <a className="nav-link main-list" onClick={reopenTutorial}>
                        <i className="fas fa-play-circle">

                        </i>
                    </a>
                </li>
                <ShareFlyer forceDrawerMini={props.forceDrawerMini} />
            </ul>
        </div>
    )
}

export default BottomPrerollBar;