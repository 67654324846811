import actions from './action';
import { idCampaignName, myGroceryListName } from '../../helper/constant';
import { serverResponse } from '../../util/fakeServer';
import axios from 'axios';

export default function rootReducer(state, action) {

    var list =  state !== undefined ? state.list : [];
    try {
        const idCampaign = localStorage.getItem(idCampaignName);
        if (idCampaign !== serverResponse.leaflet.id_campaign) {
            localStorage.setItem(idCampaignName, serverResponse.leaflet.id_campaign);
            localStorage.setItem(myGroceryListName + serverResponse.leaflet.code, JSON.stringify([]));
        }
        if (localStorage.getItem(myGroceryListName + serverResponse.leaflet.code) !== null) {
            list = JSON.parse(localStorage.getItem(myGroceryListName + serverResponse.leaflet.code));
        } else {
        }
    } catch {
    }

    state = {
        idCampaign: serverResponse.leaflet.id_campaign,
        // list: (localStorage.getItem(myGroceryListName + serverResponse.leaflet.code) !== null) ? JSON.parse(localStorage.getItem(myGroceryListName + serverResponse.leaflet.code)) : []
        list: list,
    }
    switch (action.type) {
        case actions.ADD_PRODUCT:
            //TODO
            //axios per l'incremento del counter dell'acquisto di un prodotto
            /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
     
                }).catch(error => {
                    //console.log(error);
                });*/
            action.newItem.id = action.newItem.item_id;
            let index = search(action.newItem, state.list);
            if (index === -1) {
                //action.newItem.currentPrice = (action.newItem.price * action.newItem.quantity).toFixed(2);
                action.newItem.currentPrice = ((action.newItem.price / action.newItem.equivalence) * action.newItem.quantity).toFixed(2);
                // eliminazione delle informazioni superflue
                let item = Object.assign({},
                    //{ category: action.newItem.category }, //category
                    //{ fidelity_product: action.newItem.fidelity_product }, //fidelity_product
                    //{ grammage: action.newItem.grammage }, //grammage
                    //{ markers: action.newItem.markers }, //markers
                    //{ subcategory: action.newItem.subcategory }, //subcategories
                    //{ varieties: action.newItem.varieties } //varieties
                    { item_id: action.newItem.item_id }, //item_id
                    { currentPrice: action.newItem.currentPrice }, //currentPrice
                    { variety: action.newItem.variety }, //variety
                    { description: action.newItem.description }, //description
                    { done: action.newItem.done }, //done
                    { equivalence: action.newItem.equivalence }, //equivalence
                    { field1: action.newItem.field1 }, //field1
                    { field2: action.newItem.field2 }, //field2
                    { field3: action.newItem.field3 }, //field3
                    { field4: action.newItem.field4 }, //field4
                    { grocery_label: action.newItem.grocery_label }, //grocery_label
                    { id: action.newItem.id }, //id
                    { images: action.newItem.images }, //images
                    { price: action.newItem.price }, //price
                    { price_label: action.newItem.price_label }, //price_label
                    { quantity: action.newItem.quantity }, //quantity
                    { quantity_step: action.newItem.quantity_step }, //quantity_step
                );
                state.list.push(item);
                //state.list.push(action.newItem);
            } else {
                state.list[index].quantity += action.newItem.quantity;
                //state.list[index].currentPrice = (state.list[index].price * state.list[index].quantity).toFixed(2);
                state.list[index].currentPrice = ((state.list[index].price / state.list[index].equivalence) * state.list[index].quantity).toFixed(2);
            }
            break;
        case actions.CHANGE_QUANTITY:
            //TODO
            //axios per il cambiamento del counter della quantita di un prodotto
            /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
     
                }).catch(error => {
                    //console.log(error);
                });*/
            try {
                let index1 = search(action.item, state.list);
                state.list[index1].quantity = action.quantity;
                state.list[index1].currentPrice = ((state.list[index1].price / state.list[index1].equivalence) * state.list[index1].quantity).toFixed(2);
            } catch { 
            }
            break;
        case actions.CHECK_PRODUCT:
            //TODO
            //axios per il cambiamento del counter per il check di un prodotto
            /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
     
                }).catch(error => {
                    //console.log(error);
                });*/
            let index2 = search(action.item, state.list);
            state.list[index2].done = !state.list[index2].done;
            break;
        case actions.DELETE_PRODUCT:
            //TODO
            //axios per l'incremento del counter per la cancellazione di un prodotto
            /*axios({
                method: 'post',
                url: '',
                headers: {
                },
            })
                .then(res => {
     
                }).catch(error => {
                    //console.log(error);
                });*/
            let index3 = search(action.item, state.list);
            state.list.splice(index3, 1);
            break;
        case actions.DELETE_ALL:
            state.list = [];
            break;
        default: break;
    }
    try {
        localStorage.setItem(myGroceryListName + serverResponse.leaflet.code, JSON.stringify(state.list));
    } catch {
        // state.list = state.list;
    }
    return {
        ...state,
    }
}

const search = (item, inputArray) => {
    for (let i = 0; i < inputArray.length; i++) {
        if (inputArray[i].item_id === item.id && inputArray[i].variety === item.variety) {
            return i;
        }
    }
    return -1;
}