import React, { Component } from 'react';
import MyGroceryElement from '../components/myGroceryList/MyGroceryElement';
import PageTitle from 'components/common/PageTitle';
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Container
} from 'reactstrap';
import '../assets/css/custom.css';

import { idCampaignName, myGroceryListName } from '../helper/constant';
import { serverResponse } from '../util/fakeServer';
import ReactGA from 'react-ga';

import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class MyGroceryList extends Component {

    state = {
        list: []
    }

    componentWillUnmount() {
        document.body.style.overflowY = "hidden";
    }

    componentDidMount() {
        var tmpProducts = this.props.groceryList.list;

        tmpProducts.forEach((element) => {
            element.image_file = element.images[0] ? element.images[0].image_file : "";
        })

        this.setState({
            list: tmpProducts
        });

        document.body.style.overflowY = "auto";

        setTimeout(() => {
            this.saveAsImage(this.state.list);
        }, 1000)

        // if (serverResponse.config.ga_active) {
        //     ReactGA.initialize(serverResponse.config.ga_tracking_id, {
        //         gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
        //     })
        //     ReactGA.pageview("/" + serverResponse.config.release_id + "/stampa-lista-spesa");
        // }
    }

    checkProduct = (e) => {

        let tmpProducts = this.state.list;
        tmpProducts[e.target.id].done = e.target.checked;
        tmpProducts.sort((a, b) => {
            const [aDone, bDone] = [a.done, b.done];
            if (aDone === bDone) {
                const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                if (aID === -1) return +1
                if (bID === -1) return -1
                return aID - bID
            };
            if (aDone === true) return +1;
            if (bDone === true) return -1;
            return 1;
        });
        this.setState({
            list: tmpProducts
        });

    }

    sortItems = (tmpProducts, e) => {
        tmpProducts[e.target.id].done = e.target.checked;
        tmpProducts.sort((a, b) => {
            const [aDone, bDone] = [a.done, b.done];
            if (aDone === bDone) {
                const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                if (aID === -1) return +1
                if (bID === -1) return -1
                return aID - bID
            };
            if (aDone === true) return +1;
            if (bDone === true) return -1;
            return 1;
        });
        return tmpProducts;
    }

    backToLeaflet = () => {
        localStorage.setItem(myGroceryListName + serverResponse.leaflet.code, JSON.stringify(this.state.list));
        localStorage.setItem(idCampaignName, (this.state.list ? this.state.list[0].id_campagna : ''));
        window.location = "/leaflet";
    }

    saveAsImage = (list) => {
        html2canvas(document.getElementById('print-list'), {
            allowTaint: false,
            useCORS: true
        }).then(function (canvas) {
            console.log(canvas);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
            });
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('Lista della spesa.pdf');
        });

    }

    render() {
        let tot = 0;
        const items = this.state.list;
        items.map((element, key) => {
            tot += parseFloat(element.currentPrice);
            element.index = key;
            element.images = [];
            element.images.push({ "image_file": element.image_file });
            return null;
        });
        tot = tot.toFixed(2);
        var itemsList = this.state.list;
        return (
            <div>
                <Container>
                    <Row style={{ marginTop: "10px" }}>
                        {/* className="my-grocery-card" */}
                        <Col lg="6" sm="6" md={{ size: 6 }}>
                            <a id="toBack" style={{ textDecoration: "none" }} href={process.env.PUBLIC_URL + "/"}>
                                <Row className="custom-grocery-back-btn btn-c" style={{ width: '90%' }}>
                                    <span style={{ textAlign: "center", width: "100%" }}>TORNA AL VOLANTINO</span>
                                </Row>
                            </a>
                        </Col>
                        <Col lg="6" sm="6" md={{ size: 6 }}>
                            <div style={{ textDecoration: "none" }} onClick={() => { this.saveAsImage(itemsList) }}>
                                <Row className="custom-grocery-back-btn btn-c" style={{ width: '90%', float: 'right' }}>
                                    <span id="print-label" style={{ textAlign: "center", width: "100%" }}>SALVA COME PDF</span>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div id="print-list" className="custom-container">
                    <MetaTags>
                        <title>La mia lista della spesa</title>
                        <meta property="og:url" content={serverResponse.config.serverPath + "/myGroceryList"} />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content="Lista della spesa | Volantino Interattivo" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />
                    </MetaTags>

                    <PageTitle title="myGroceryList" className="ml-15" />


                    <Row style={{ marginBottom: "20px" }}>
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            {this.state.list.map((element, i) => <MyGroceryElement info={element} key={i} checkProduct={this.checkProduct} />)}

                            <Col sm="12" md={{ size: 6, offset: 3 }} className="my-grocery-card">
                                <Row className="custom-grocery-back-btn" style={{ cursor: "auto", marginBottom: "0" }}>
                                    <span style={{ textAlign: "center", width: "100%" }}>Totale: € {tot}</span>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

// export default MyGroceryList;

const mapStateToProps = state => {

    return {
        groceryList: state.groceryList,
    };

}

export default connect(mapStateToProps, null)(MyGroceryList);